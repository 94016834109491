import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getPosts } from "../../store/actions/post";
import { useNavigate } from "react-router-dom";
import L from "leaflet";
import { Link } from "react-router-dom";
import { formatVietnameseToString } from "../../ultils/Common/formatVietnameseToString";
import { Item } from "../../components";
const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon1 - lon2) * Math.PI) / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
};

const redIcon = new L.Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/512/252/252025.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const geocodeWithOpenCage = async (address) => {
    const apiKey = '6c3cba82d0af43a3aa8811f0cbff9704';
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(address)}&key=${apiKey}`;

    try {
        const response = await axios.get(url);
        if (response.data && response.data.results && response.data.results.length > 0) {
            const { lat, lng } = response.data.results[0].geometry;
            return { lat: parseFloat(lat), lon: parseFloat(lng), address: response.data.results[0].formatted };
        } else {
            console.error('No results found for address:', address);
            return null;
        }
    } catch (error) {
        console.error('Geocoding error:', error.response ? error.response.data : error.message);
        return null;
    }
};

const BackButton = () => {
    const navigate = useNavigate();

    return (
        <button
            onClick={() => navigate('/')}
            style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                padding: '8px 16px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                zIndex: 1000,
            }}
        >
            Trở về
        </button>
    );
};

const QuetViTri = () => {
    const [inputAddress, setInputAddress] = useState("");
    const [distance, setDistance] = useState(1);
    const [location, setLocation] = useState(null); // Store selected coordinates
    const [nearbyPosts, setNearbyPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showMap, setShowMap] = useState(false); // Control when the map is displayed
    const dispatch = useDispatch();
    const { posts } = useSelector((state) => state.post);
    console.log('posts: ', posts);

    // Fetch posts when the component mounts
    useEffect(() => {
        console.log("Fetching posts...");
        dispatch(getPosts()).then((response) => {
            console.log("Posts fetched successfully:", response);
        }).catch((error) => {
            console.error("Error fetching posts:", error);
        });
    }, [dispatch]);


    // Watch for changes in posts and reset nearbyPosts if needed
    useEffect(() => {
        if (!posts || posts.length === 0) {
            console.log("Posts not yet available");
            return;
        }
        if (location) {
            filterPostsByDistance(location);
        }
    }, [posts, location]);


    // Define the missing filterPostsByDistance function
    const filterPostsByDistance = (coords) => {
        const filteredPosts = posts.filter((post) => {
            console.log('post: ', post); // Log each post
            if (!post.coordinate) {
                console.log('Missing coordinate for post: ', post); // Log if coordinate is missing
                return false;
            }

            const [lat, lon] = post.coordinate.split(',').map(Number);
            console.log('coordinate: ', post.coordinate.split(',').map(Number)); // Log coordinate split

            if (isNaN(lat) || isNaN(lon)) return false; // Ensure valid coordinates
            const dist = calculateDistance(coords[0], coords[1], lat, lon);
            return dist <= distance;
        });

        setNearbyPosts(filteredPosts);
    };


    const handleSearch = async () => {
        setLoading(true);
        try {
            // Geocode the input address to get new coordinates
            const inputCoords = await geocodeWithOpenCage(inputAddress);
            if (!inputCoords) {
                alert("Không tìm thấy địa chỉ nhập vào.");
                setLoading(false);
                return;
            }
            const searchCoords = [inputCoords.lat, inputCoords.lon];

            // Update the location state with the new coordinates from geocoding
            setLocation(searchCoords);

            // Filter posts based on the newly obtained coordinates
            filterPostsByDistance(searchCoords);

            setShowMap(true); // Show the map after search

        } catch (error) {
            console.error("Error during search:", error);
            alert("Đã xảy ra lỗi trong quá trình tìm kiếm.");
        } finally {
            setLoading(false);
        }
    };

    const handleMapClick = async (e) => {
        const { lat, lng } = e.latlng;
        const response = await axios.get(
            `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&key=6c3cba82d0af43a3aa8811f0cbff9704`
        );
        if (response.data.results.length > 0) {
            const selectedAddress = response.data.results[0].formatted;
            setInputAddress(selectedAddress);
            setLocation([lat, lng]); // Update location on map click

            // Re-filter posts after map click
            filterPostsByDistance([lat, lng]);
        }
    };
    const renderItems = (items) => (
        items.map((item) => {
            if (!item) return null;

            let images = [];

            if (item.images && item.images.image) {
                if (typeof item.images.image === 'string') {
                    images = item.images.image.split(',');
                } else if (Array.isArray(item.images.image)) {
                    images = item.images.image;
                } else if (
                    typeof item.images.image === 'object' &&
                    item.images.image.type === 'Buffer' &&
                    Array.isArray(item.images.image.data)
                ) {
                    const urlString = String.fromCharCode(...item.images.image.data);
                    images = urlString.split(',');
                } else {
                    console.error("Unexpected image format:", item.images.image);
                }
            }

            return (
                <Item
                    key={item.id}
                    address={item.address}
                    attributes={item.attributes}
                    description={item.description}
                    images={images}
                    star={+item.star}
                    title={item.title}
                    user={item.user}
                    id={item.id}
                />
            );
        })
    );
    return (
        <div className="w-full flex flex-col items-center">
            <h1 className="text-2xl mb-4">Quét Vị Trí</h1>
            <BackButton />
            <input
                type="text"
                value={inputAddress}
                onChange={(e) => setInputAddress(e.target.value)}
                placeholder="Nhập địa chỉ bằng tiếng Việt"
                className="border p-2 rounded mb-4 w-3/4"
            />
            <input
                type="number"
                value={distance}
                onChange={(e) => setDistance(e.target.value)}
                placeholder="Nhập khoảng cách (km)"
                className="border p-2 rounded mb-4 w-3/4"
                min="0.1"
                step="0.1"
            />
            <button
                onClick={handleSearch}
                className="px-4 py-2 bg-blue-600 text-white rounded mb-4 disabled:opacity-50"
                disabled={loading || (!location && !inputAddress) || distance <= 0}
            >
                {loading ? "Đang tìm kiếm..." : "Tìm kiếm"}
            </button>
            {location && (
                <MapComponent center={location} posts={nearbyPosts} onMapClick={handleMapClick} />
            )}

            {nearbyPosts.length > 0 && (
                <div className="w-full p-2 bg-white shadow-md rounded-md px-6">
                    <div className="grid md:grid-cols-2 gap-4">
                        {renderItems(nearbyPosts)}
                    </div>
                </div>
            )}
        </div>
    );
};

const MapClickHandler = ({ onMapClick }) => {
    useMapEvents({
        click: onMapClick,
    });
    return null;
};

const MapComponent = ({ center, posts, onMapClick }) => {
    return (
        <MapContainer
            center={center}
            zoom={13}
            style={{ height: "500px", width: "100%" }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; OpenStreetMap contributors'
            />
            <MapClickHandler onMapClick={onMapClick} />
            <Marker position={center} icon={redIcon}>
                <Popup>Vị trí bạn đã chọn</Popup>
            </Marker>
            {posts.map((post, index) => {
                console.log('Rendering post: ', post); // Add log to check if posts are being rendered
                if (!post.coordinate) {
                    console.log('Post without coordinates: ', post);
                    return null;
                }

                const [lat, lon] = post.coordinate.split(',').map(Number);
                console.log('[lat, lon]: ', [lat, lon]); // Ensure this log happens

                return (
                    <Marker
                        key={index}
                        position={[lat, lon]}
                    >
                        <Popup>
                            <Link to={`/chi-tiet/${formatVietnameseToString(post.title?.replaceAll('/', ''))}/${post.id}`}>
                                <strong>{post.title || "Không có tiêu đề"}</strong>
                            </Link>
                            <br />
                            {post.address}
                        </Popup>
                    </Marker>
                );
            })}

        </MapContainer>
    );
};

export default QuetViTri;
