import React, { useEffect, useState } from 'react'
import { Overview, Address, Loading, Button } from '../../components'
import { apiUploadImages, apiCreatePost, apiUpdatePost, apiUploadVideos } from '../../services'
import icons from '../../ultils/icons'
import { getCodes, getCodesArea } from '../../ultils/Common/getCodes'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import validate from '../../ultils/Common/validateFields'
import { useDispatch } from 'react-redux'
import { resetDataEdit } from '../../store/actions'


const { BsCameraFill, ImBin } = icons

const CreatePost = ({ isEdit }) => {
    const dispatch = useDispatch();
    const { dataEdit } = useSelector(state => state.post);
    const [payload, setPayload] = useState(() => {
        const initData = {
            categoryCode: dataEdit?.categoryCode || '',
            title: dataEdit?.title || '',
            priceNumber: dataEdit?.priceNumber * 1000000 || 0,
            areaNumber: dataEdit?.areaNumber || 0,
            images: dataEdit?.images?.image ? JSON.parse(dataEdit?.images?.image) : '',
            videos: dataEdit?.videos?.video ? JSON.parse(dataEdit?.videos?.video) : '',
            address: dataEdit?.address || '',
            priceCode: dataEdit?.priceCode || '',
            areaCode: dataEdit?.areaCode || '',
            description: dataEdit?.description ? JSON.parse(dataEdit?.description) : '',
            target: dataEdit?.overviews?.target || '',
            province: dataEdit?.province || ''
        };
        return initData;
    });
    const [imagesPreview, setImagesPreview] = useState([]);
    const [videosPreview, setVideosPreview] = useState([]);
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [isLoadingVideo, setIsLoadingVideo] = useState(false);
    const { prices, areas, categories, provinces } = useSelector(state => state.app);
    const { currentData } = useSelector(state => state.user);
    const [invalidFields, setInvalidFields] = useState([]);

    useEffect(() => {
        if (dataEdit) {
            // Parsing images and videos
            try {
                const parsedImages = dataEdit.images?.image ? JSON.parse(dataEdit.images.image) : [];
                const parsedVideos = dataEdit.videos?.video ? JSON.parse(dataEdit.videos.video) : [];
                setImagesPreview(parsedImages);
                setVideosPreview(parsedVideos);
            } catch (error) {
                console.error('Error parsing data:', error);
            }
        }
    }, [dataEdit]);

    const handleFiles = async (e) => {
        e.stopPropagation();
        setIsLoadingImage(true);
        let newImages = [];
        let files = e.target.files;
    
        for (let file of files) {
            let formData = new FormData();
            formData.append('images', file); // Ensure this matches your backend key
    
            try {
                let response = await apiUploadImages(formData);
                if (response.imagePaths) {
                    newImages.push(...response.imagePaths); // Add the full image URLs to the array
                } else {
                    console.error('Image upload failed:', response);
                }
            } catch (error) {
                console.error('Error during image upload:', error);
            }
        }
    
        setIsLoadingImage(false);
        setImagesPreview(prev => [...prev, ...newImages]);
        setPayload(prev => ({
            ...prev,
            images: [...prev.images, ...newImages]
        }));
    };
    
    const handleVideos = async (e) => {
        e.stopPropagation();
        setIsLoadingVideo(true);
        let newVideos = [];
        let files = e.target.files;
    
        for (let file of files) {
            let formData = new FormData();
            formData.append('file', file);
    
            try {
                let response = await apiUploadVideos(formData);  // Upload each file one at a time
                if (response.videoPaths) {
                    newVideos.push(...response.videoPaths);  // Append video URLs
                } else {
                    console.error('Video upload failed:', response);
                }
            } catch (error) {
                console.error('Error during video upload:', error);
            }
        }
    
        setIsLoadingVideo(false);
        
        // Add a 2-second delay before updating the preview
        setTimeout(() => {
            setVideosPreview(prev => [...prev, ...newVideos]);
            setPayload(prev => ({
                ...prev,
                videos: [...prev.videos, ...newVideos]
            }));
        }, 2000); // 2-second delay
    };
    


    const handleDeleteImage = (image) => {
        setImagesPreview(prev => prev.filter(item => item !== image));
        setPayload(prev => ({
            ...prev,
            images: prev.images.filter(item => item !== image)
        }));
    };

    const handleDeleteVideo = (video) => {
        setVideosPreview(prev => prev.filter(item => item !== video));
        setPayload(prev => ({
            ...prev,
            videos: prev.videos.filter(item => item !== video)
        }));
    };

    const handleSubmit = async () => {
        let priceCodeArr = getCodes(+payload.priceNumber / Math.pow(10, 6), prices, 1, 15);
        let priceCode = priceCodeArr[0]?.code;
        let areaCodeArr = getCodesArea(+payload.areaNumber, areas, 0, 90);
        let areaCode = areaCodeArr[0]?.code;

        let finalPayload = {
            ...payload,
            priceCode,
            areaCode,
            userId: currentData.id,
            priceNumber: +payload.priceNumber / Math.pow(10, 6),
            target: payload.target || 'Tất cả',
            label: `${categories?.find(item => item.code === payload?.categoryCode)?.value} ${payload?.address?.split((',')[0])}`
        };

        // Remove the videos key if it's empty
        if (!finalPayload.videos || finalPayload.videos.length === 0) {
            delete finalPayload.videos;
        }

        const result = validate(finalPayload, setInvalidFields);
        if (result === 0) {
            if (dataEdit && isEdit) {
                finalPayload.postId = dataEdit?.id;
                finalPayload.attributesId = dataEdit?.attributesId;
                finalPayload.imagesId = dataEdit?.imagesId;
                finalPayload.overviewId = dataEdit?.overviewId;

                const response = await apiUpdatePost(finalPayload);
                if (response?.data.err === 0) {
                    Swal.fire('Thành công', 'Đã cập nhật thành công', 'success').then(() => {
                        resetPayload();
                        dispatch(resetDataEdit());
                    });
                } else {
                    Swal.fire('Oops!', 'Đã xảy ra lỗi', 'error');
                }
            } else {
                const response = await apiCreatePost(finalPayload);
                if (response?.data.err === 0) {
                    Swal.fire('Thành công', 'Đã thêm bài đăng mới', 'success').then(() => {
                        resetPayload();
                    });
                } else {
                    Swal.fire('Oops!', 'Đã xảy ra lỗi', 'error');
                }
            }
        }
    };

    const resetPayload = () => {
        setPayload({
            categoryCode: '',
            title: '',
            priceNumber: 0,
            areaNumber: 0,
            images: '',
            videos: '',
            address: '',
            priceCode: '',
            areaCode: '',
            description: '',
            target: '',
            province: ''
        });
        setImagesPreview([]);
        setVideosPreview([]);
    };

    return (
        <div className='px-6'>
            <h1 className='text-3xl font-medium py-4 border-b border-gray-200'>{isEdit ? 'Chỉnh sửa tin đăng' : 'Đăng tin mới'}</h1>
            <div className='flex gap-4'>
                <div className='py-4 flex flex-col gap-8 flex-auto'>
                    <Address invalidFields={invalidFields} setInvalidFields={setInvalidFields} payload={payload} setPayload={setPayload} />
                    <Overview invalidFields={invalidFields} setInvalidFields={setInvalidFields} payload={payload} setPayload={setPayload} />
                    <div className='w-full mb-6'>
                        <h2 className='font-semibold text-xl py-4'>Hình ảnh</h2>
                        <small>Cập nhật hình ảnh rõ ràng sẽ cho thuê nhanh hơn</small>
                        <div className='w-full'>
                            <label className='w-full border-2 h-[200px] my-4 gap-4 flex flex-col items-center justify-center border-gray-400 border-dashed rounded-md' htmlFor="file">
                                {isLoadingImage
                                    ? <Loading />
                                    : <div className='flex flex-col items-center justify-center'>
                                        <BsCameraFill color='blue' size={50} />
                                        Thêm ảnh
                                    </div>}
                            </label>
                            <input onChange={handleFiles} hidden type="file" id='file' multiple />
                            <small className='text-red-500 block w-full'>
                                {invalidFields?.some(item => item.name === 'images') && invalidFields?.find(item => item.name === 'images')?.message}
                            </small>
                            <div className='w-full'>
                                <h3 className='font-medium py-4'>Ảnh đã chọn</h3>
                                <div className='flex gap-4 items-center'>
                                    {imagesPreview?.map((item, index) => (
                                        <div key={index} className='relative w-1/3 h-[200px]'>
                                            <img src={item} alt="preview" className='w-full h-full object-cover rounded-md' />
                                            <span
                                                title='Xóa'
                                                onClick={() => handleDeleteImage(item)}
                                                className='absolute top-0 right-0 p-2 cursor-pointer bg-gray-300 hover:bg-gray-400 rounded-full'
                                            >
                                                <ImBin />
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-full mb-6'>
                        <h2 className='font-semibold text-xl py-4'>Video</h2>
                        <small>Cập nhật video rõ ràng sẽ cho thuê nhanh hơn</small>
                        <div className='w-full'>
                            <label className='w-full border-2 h-[200px] my-4 gap-4 flex flex-col items-center justify-center border-gray-400 border-dashed rounded-md' htmlFor="video">
                                {isLoadingVideo
                                    ? <Loading />
                                    : <div className='flex flex-col items-center justify-center'>
                                        <BsCameraFill color='blue' size={50} />
                                        Thêm video
                                    </div>}
                            </label>
                            <input onChange={handleVideos} hidden type="file" id='video' multiple />
                            <small className='text-red-500 block w-full'>
                                {invalidFields?.some(item => item.name === 'videos') && invalidFields?.find(item => item.name === 'videos')?.message}
                            </small>
                            <div className='w-full'>
                                <h3 className='font-medium py-4'>Video đã chọn</h3>
                                <div className='flex gap-4 items-center'>
                                    {videosPreview?.map(item => {
                                        return (
                                            <div key={item} className='relative w-1/3 h-1/3 '>
                                                <video src={item} alt="preview" className='w-full h-full object-cover rounded-md' controls />
                                                <span
                                                    title='Xóa'
                                                    onClick={() => handleDeleteVideo(item)}
                                                    className='absolute top-0 right-0 p-2 cursor-pointer bg-gray-300 hover:bg-gray-400 rounded-full'
                                                >
                                                    <ImBin />
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={handleSubmit}
                        text={isEdit ? 'Cập nhật' : 'Tạo mới'}
                        bgColor='bg-green-600'
                        textColor='text-white' />
                    <div className='h-[500px]'>

                    </div>
                </div>
            </div>
        </div>
    );
};



export default CreatePost